<template>
    <el-dialog :title="goods_title_id ? '修改账户' : '添加账户'" :visible.sync="confitionDialog" append-to-body custom-class='class-dialog' width="700px" @close="onCancel" :close-on-click-modal='false' :close-on-press-escape='false'>
        <div class="dialog-inside videoDialog">
            <div class="sunbox">
                <div class="sunbox-text">账户ID</div>
                <div class="sunbox-content" style="width:500px">
                    <el-input v-model="goods_name" placeholder="请输入账户ID" type="textarea" :rows='10'></el-input>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="default" @click="onCancel" class="button-Dialog">取 消</el-button>
            <el-button type="primary" @click="onSubmit" class="button-Dialog">提 交</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            confitionDialog: false,
            TypeList: [],//分类列表
            goods_title_id: '',//文案id
            goods_name: '',//文案名称
            video_type: '',//分类id
        };
    },

    mounted() {
    },

    methods: {
        //分类列表
        // ongetVideoTypeList(){
        //     this.$service.get(this.$api.getVideoTypeList,{}, (res)=> {
        //         if(res.code == '200'){
        //             this.TypeList = res.data
        //         }
        //     })
        // },
        //提交
        onSubmit() {
            if (this.goods_name == '') { this.$message({ message: "请填写账户ID", type: "warning" }); return; }
            var adv_ids = this.goods_name.split('\n');
            var filtered = adv_ids.filter(function (el) {
                return el != "";
            });
            var param = {
                adv_ids: filtered
            }
            this.goods_title_id ? param.goods_title_id = this.goods_title_id : ''
            this.$service.post(this.$api.add_manyAccount, param, (res) => {
                if (res.code == '200') {
                    this.$message({ message: this.goods_title_id ? '修改账户成功' : '添加账户成功', type: 'success' })
                    // this.$emit('ongetVideoGoodsList');
                    this.$parent.ongetAccount()
                    this.onCancel()
                }
            })
        },
        //取消
        onCancel() {
            this.confitionDialog = false
            this.TypeList = [],//分类列表
                this.goods_title_id = '',//文案id
                this.goods_name = '',//文案名称
                this.video_type = ''//分类id
        },
        //添加分类弹窗
        onDialog(item) {
            if (item) {
                this.goods_title_id = item.id//账户ID
                // this.video_type = item.video_type.id//分类id
                this.goods_name = item.adv_id//账户标题
            }
            this.confitionDialog = true
        },
    },



    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
.classifyList {
    .group-table {
        .TableList {
            .el-tag {
                cursor: pointer;
                margin-bottom: 10px;
                margin-right: 20px;
            }
        }
    }
}
</style>
<style lang="scss">
</style>